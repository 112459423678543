import React from "react";
import "../../components/events/events.css";
import sial from "../../assets/eventspics/sial.jpg";
import earthquake from "../../assets/eventspics/earthquake.webp";
import gulfood from "../../assets/eventspics/gulfood2.jpg";

const EventCard = (props) => {
  return (
    <div key={props.index} className="events-item-event-container">
      <img src={props.event.link} alt={props.event.name} />
      <div className="events-item-protuct-text">
        <p className="event-name">{props.event.name}</p>
        <p>{props.event.description}</p>
        <p className="event-category">{props.event.category}</p>
      </div>
    </div>
  );
};

const EventsFr = () => {
  const eventList = {
    events: [
      {
        name: "GOLD CHEESE MOROCCO participe dans le Gulfood 2025",
        id: "gulfood2025",
        description:
          "Nous sommes ravis d'annoncer que Gold Cheese Morocco participera en tant qu'exposant à la foire internationale Gulf Food, qui se tiendra du 17 février au 21 février au Pavillon marocain, stand 35. Rejoignez-nous pour découvrir nos produits fromagers de haute qualité et explorer les opportunités de collaboration avec notre équipe. Nous sommes impatients de partager notre passion pour les fromages avec vous et de vous présenter nos dernières créations. Nous vous attendons pour une proposition d'affaire et partager notre expérience et savoir avec vous. Stand : M-G22. Hall: Shk Maktoum Hall. Pavillon : Morocco.",
        link: gulfood,
      },
      {
        name: "GOLD CHEESE MOROCCO participe dans le SIAL Paris 2024",
        id: "sialparis2024",
        description:
          "GOLD CHEESE MOROCCO est ravi d'annoncer sa participation au SIAL Paris 2024, l'un des salons internationaux de l'alimentation les plus prestigieux. Cet événement offre une plateforme unique pour présenter nos produits fromagers de qualité supérieure à un public mondial. Les participants auront l'occasion de déguster nos saveurs distinctives et d'en apprendre davantage sur notre engagement envers la qualité et l'innovation. Nous sommes impatients de nouer des liens avec des professionnels de l'industrie, des partenaires potentiels et des passionnés de gastronomie du monde entier. Rejoignez-nous au SIAL Paris pour découvrir le riche patrimoine culinaire du fromage marocain !",
        link: sial,
      },
      {
        name: "GOLD CHEESE MOROCCO helps with the Earthquake.",
        id: "earthquake2023",
        date: "23-09-2023",
        description:
          "En réponse au dévastateur tremblement de terre qui a frappé le Maroc en 2023, Gold Cheese Morocco a agi de manière significative en fournissant des vivres indispensables aux communautés touchées. Reconnaissant l'importance de la nutrition en période difficile, notre équipe s'est rapidement mobilisée pour offrir nos produits à ceux qui en avaient besoin. Bien que notre contribution ait pu sembler modeste, elle faisait partie d'un effort plus large pour soutenir la récupération et remonter le moral. Nous sommes fiers de nous tenir aux côtés de nos compatriotes marocains pendant cette période difficile et restons engagés à contribuer positivement à notre communauté.",
        link: earthquake,
      },
    ],
  };
  return (
    <div className="events section__padding section">
      <div className="events-title-container">
        <h1 className="events-title">Evenements</h1>
      </div>
      {/* Events*/}
      <div className="events-item-container">
        {/* <h1>Events : </h1> <br /> */}
        {eventList.events.map((event, index) => (
          <EventCard event={event} index={index} />
        ))}
      </div>
    </div>
  );
};

export default EventsFr;
